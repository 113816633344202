.nav {
  background-color: #ffcb44;
  display: flex;
  flex-direction: row;
  & p {
    margin: 0;
    & a {
      display: block;
      padding: 4px 6px 6px 6px;
      color: rgb(73, 73, 73);
    }
    & a:hover {
      color: #ffcb44;
      background-color: rgb(70, 130, 50);
    }
    & a.on {
      color: rgb(255, 230, 230);
      background-color: rgb(70, 130, 50);
    }
  }
}
